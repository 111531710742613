import { useState, useEffect } from 'react';

let FUTURES_API_KEY = process.env.REACT_APP_FUTURES_API_KEY;
let FUTURES_API_URL = process.env.REACT_APP_FUTURES_API_URL;

const DynamicTable = (props) => {
  const [tableData, setTableData] = useState([]);
  const [uniqueEventsData, setEventsData] = useState([]);

  const fetchData = async () => {
    const apiKey = FUTURES_API_KEY;
    const url = FUTURES_API_URL;
    try {
      const response = await fetch(url, {
        headers: {
          'x-api-key': apiKey,
          'sub-path': props.subPath,
        },
      });
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log(tableData);
  useEffect(() => {
    // Filter and update unique combinations only after tableData is updated.
    const uniqueEvents = new Set();
    tableData.forEach((item) => {
      const futureEvent = item.event;
      uniqueEvents.add(futureEvent);
    });
    setEventsData(Array.from(uniqueEvents));
  }, [tableData, props.week]);

  return (
    <div>
      {uniqueEventsData.map((event) => (
        <div key={event}>
          <h2>
            {event}
          </h2>
			    <h4>Last Updated: {new Date(tableData.filter((row) => row.event === event)[0].identified_at).toLocaleString()}</h4>
			    <table>
			      <thead>
			    	<tr>
			    	  <th>Option</th>
			    	  <th>Vegas</th>
			    	  <th>GambetDC</th>
			    	  <th>Diff</th>
			    	</tr>
			      </thead>
			      <tbody>
            {tableData
                .filter((row) => row.event === event)
                .map((row, index) => (
			    	  <tr key={index}>
			    		<td>{row.option_name}</td>
			    		<td>{parseFloat(row.pinnacle_implied_odds).toFixed(2)+'%'}</td>
			    		<td>{parseFloat(row.gambet_implied_odds).toFixed(2)+'%'}</td>
			    		<td>{parseFloat(row.odds_diff).toFixed(2)+'%'}</td>
			    	  </tr>
			    	))}
			    	<tr>
			    	<td><b>Total Market</b></td>
			    	<td><b>{parseFloat(tableData.filter((row) => row.event === event).reduce((acc, row) => acc + parseFloat(row.pinnacle_implied_odds), 0)).toFixed(2)+'%'}</b></td>
			    	<td><b>{parseFloat(tableData.filter((row) => row.event === event).reduce((acc, row) => acc + parseFloat(row.gambet_implied_odds), 0)).toFixed(2)+'%'}</b></td>
			    	<td><b>{parseFloat(tableData.filter((row) => row.event === event).reduce((acc, row) => acc + parseFloat(row.odds_diff), 0)).toFixed(2)+'%'}</b></td>
			    	</tr>
			      </tbody>
			    </table>
          <br></br>
        </div>
      ))}
    </div>
  );
};

export default DynamicTable;
