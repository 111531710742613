const Navbar = () => {
	return (
		<nav className="navbar">
			<h1><a href="/">fancyworms</a></h1>
			<div className="links">
				<a href="/betsfriend">BetsFriend</a>
				<a href="/">Blog</a>
			</div>
		</nav>
	);
}

export default Navbar