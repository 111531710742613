import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import logo from './logo.svg';
import Navbar from './Navbar';

import Home from './Home';
import About from './About';
import Footer from './components/Footer';

import Betsfriend from './bf/Betsfriend';
import BetsfriendNFLFutures from './bf/BetsfriendNFLFutures';
import BetsfriendNFLWeeklies from './bf/BetsfriendNFLWeeklies';
import BetsfriendNFLNotifications from './bf/BetsfriendNFLNotifications';
import BetsfriendBets from './bf/BetsfriendBets.js';


// static blog routes... fix this later
import Blog2 from './blog/2';
import Blog3 from './blog/3';
import Blog4 from './blog/4';
import Blog5 from './blog/5';
import Blog6 from './blog/6';
import Blog7 from './blog/7';
import Blog8 from './blog/8';
import Blog9 from './blog/9';
import Blog10 from './blog/10';
import Blog11 from './blog/11';
import Blog12 from './blog/12';



function App() {
  return (
    <Router>
    <div className="App">
    <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/about" element={<About />} /> 
          <Route path="/Betsfriend" element={<Betsfriend />} /> 
          <Route path="/bf/NFL/Futures" element={<BetsfriendNFLFutures />} /> 
          <Route path="/bf/NFL/Weeklies" element={<BetsfriendNFLWeeklies />} /> 
          <Route path="/bf/BetsfriendBets" element={<BetsfriendBets />} /> 
          <Route path="/bf/NFL/Notifs" element={<BetsfriendNFLNotifications />} />
          <Route path="/blog/2" element={<Blog2 />} />    
          <Route path="/blog/3" element={<Blog3 />} />    
          <Route path="/blog/4" element={<Blog4 />} />    
          <Route path="/blog/5" element={<Blog5 />} />    
          <Route path="/blog/6" element={<Blog6 />} />    
          <Route path="/blog/7" element={<Blog7 />} />    
          <Route path="/blog/8" element={<Blog8 />} />    
          <Route path="/blog/9" element={<Blog9 />} />    
          <Route path="/blog/10" element={<Blog10 />} />    
          <Route path="/blog/11" element={<Blog11 />} />    
          <Route path="/blog/12" element={<Blog12 />} />    
        </Routes>
      </div>
    <Footer />
    </div>
    </Router>
  );
}

export default App;
