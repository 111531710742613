const Footer = () => {
    const year = new Date().getFullYear();
  return (
    <div className="footer">
       © {year} <a href="/about">fancyworms</a>
       <p>fancywormshq @ gmail dot com</p>
    </div>
  );
}

export default Footer;