import { useState, useEffect } from 'react';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import React, { Fragment, Component } from 'react';

import imported_md from './markdown/5.md'

class content extends Component {
	state = {
	  post: null,
	}
	componentDidMount() {
	  fetch(imported_md)
		.then(res => res.text())
		.then(post => this.setState((state) => ({ ...state, post })))
		.catch((err) => console.error(err));
	}
  
	render() {
	  const { post } = this.state;
  
	  return (
		<div className="content">
		<Markdown children={post} remarkPlugins={[remarkGfm]}/>
		</div>
	  )
	}
  }
  
export default content;