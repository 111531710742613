import React, { useEffect, useState, useRef } from 'react';
import vegaEmbed from 'vega-embed';
import { VegaLite } from 'react-vega';

// prop results so far
const data = [
  {
      "Tweet Date": "10/15/2023",
      "Week": 6,
      "Player": "Alexander Mattinson",
      "Market option": "longest_rush",
      "Market": "under",
      "Odds": -111,
      "Advantage": 5.2,
      "Result": "win",
      "result ($)": "$9.0",
      "implied_probability": 52.6
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Allen Robinson II",
      "Market option": "receiving_yards",
      "Market": "under",
      "Odds": -120,
      "Advantage": 6.3,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 54.5
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Allen Robinson II",
      "Market option": "receiving_yards",
      "Market": "under",
      "Odds": -119,
      "Advantage": 6.3,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 54.4
  },
  {
      "Tweet Date": "10/6/2023",
      "Week": 5,
      "Player": "Azeez Al-Shaair",
      "Market option": "tackles_plus_assists",
      "Market": "over",
      "Odds": -142,
      "Advantage": 6,
      "Result": "win",
      "result ($)": "$7.0",
      "implied_probability": 58.7
  },
  {
      "Tweet Date": "10/12/2023",
      "Week": 6,
      "Player": "Azeez Al-Shaair",
      "Market option": "tackles_plus_assists",
      "Market": "over",
      "Odds": -131,
      "Advantage": 5.8,
      "Result": "win",
      "result ($)": "$7.6",
      "implied_probability": 56.7
  },
  {
      "Tweet Date": "10/13/2023",
      "Week": 6,
      "Player": "Brandon Aiyuk",
      "Market option": "receptions",
      "Market": "over",
      "Odds": -111,
      "Advantage": 6.1,
      "Result": "win",
      "result ($)": "$9.0",
      "implied_probability": 52.6
  },
  {
      "Tweet Date": "10/14/2023",
      "Week": 6,
      "Player": "Brian Robinson",
      "Market option": "rushing_attempts",
      "Market": "over",
      "Odds": -121,
      "Advantage": 5.1,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 54.7
  },
  {
      "Tweet Date": "10/5/2023",
      "Week": 5,
      "Player": "Brian Robinson Jr",
      "Market option": "rushing_attempts",
      "Market": "over",
      "Odds": -126,
      "Advantage": 5.3,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 55.8
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Chris Moore",
      "Market option": "receptions",
      "Market": "under",
      "Odds": -142,
      "Advantage": 5.6,
      "Result": "win",
      "result ($)": "$7.0",
      "implied_probability": 58.7
  },
  {
      "Tweet Date": "10/13/2023",
      "Week": 6,
      "Player": "Dameon Pierce",
      "Market option": "rushing_attempts",
      "Market": "over",
      "Odds": -121,
      "Advantage": 6,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 54.7
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Dallas Goedert",
      "Market option": "receptions",
      "Market": "over",
      "Odds": -109,
      "Advantage": 7.5,
      "Result": "win",
      "result ($)": "$9.2",
      "implied_probability": 52.1
  },
  {
      "Tweet Date": "10/08/2023",
      "Week": 5,
      "Player": "Dalton Kincaid",
      "Market option": "receptions",
      "Market": "over",
      "Odds": 122,
      "Advantage": 6.6,
      "Result": "win",
      "result ($)": "$12.2",
      "implied_probability": 45.1
  },
  {
      "Tweet Date": "10/15/2023",
      "Week": 6,
      "Player": "Dalton Schultz",
      "Market option": "receiving_yards",
      "Market": "under",
      "Odds": -116,
      "Advantage": 5.1,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 53.7
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Dameon Pierce",
      "Market option": "rushing_attempts",
      "Market": "over",
      "Odds": -129,
      "Advantage": 5.1,
      "Result": "win",
      "result ($)": "$7.8",
      "implied_probability": 56.4
  },
  {
      "Tweet Date": "10/9/2023",
      "Week": 5,
      "Player": "Davante Adams",
      "Market option": "receptions",
      "Market": "over",
      "Odds": 106,
      "Advantage": 5.6,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 48.5
  },
  {
      "Tweet Date": "10/15/2023",
      "Week": 6,
      "Player": "David Long Jr",
      "Market option": "tackles_plus_assists",
      "Market": "over",
      "Odds": -129,
      "Advantage": 7.6,
      "Result": "win",
      "result ($)": "$7.8",
      "implied_probability": 56.4
  },
  {
      "Tweet Date": "10/7/2023",
      "Week": 5,
      "Player": "DeAndre Hopkins",
      "Market option": "receptions",
      "Market": "over",
      "Odds": -103,
      "Advantage": 6.1,
      "Result": "win",
      "result ($)": "$9.7",
      "implied_probability": 50.7
  },
  {
      "Tweet Date": "10/5/2023",
      "Week": 5,
      "Player": "DJ Moore",
      "Market option": "receiving_yards",
      "Market": "over",
      "Odds": -116,
      "Advantage": 6.8,
      "Result": "win",
      "result ($)": "$8.6",
      "implied_probability": 53.7
  },
  {
      "Tweet Date": "10/15/2023",
      "Week": 6,
      "Player": "Ezekiel Elliot",
      "Market option": "rushing_attempts",
      "Market": "over",
      "Odds": -116,
      "Advantage": 5.9,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 53.7
  },
  {
      "Tweet Date": "10/13/2023",
      "Week": 6,
      "Player": "Foye Oluokun",
      "Market option": "tackles_plus_assists",
      "Market": "over",
      "Odds": -136,
      "Advantage": 5.1,
      "Result": "win",
      "result ($)": "$7.4",
      "implied_probability": 57.6
  },
  {
      "Tweet Date": "10/13/2023",
      "Week": 6,
      "Player": "Hayden Hurst",
      "Market option": "receptions",
      "Market": "under",
      "Odds": 108,
      "Advantage": 5.2,
      "Result": "win",
      "result ($)": "$10.8",
      "implied_probability": 48.8
  },
  {
      "Tweet Date": "10/9/2023",
      "Week": 5,
      "Player": "Jakobi Meyers",
      "Market option": "receptions",
      "Market": "over",
      "Odds": 100,
      "Advantage": 6.9,
      "Result": "win",
      "result ($)": "$10.0",
      "implied_probability": 50
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Jalen Hurts",
      "Market option": "longest_rush",
      "Market": "over",
      "Odds": -128,
      "Advantage": 5.5,
      "Result": "win",
      "result ($)": "$7.8",
      "implied_probability": 56.1
  },
  {
      "Tweet Date": "10/15/2023",
      "Week": 6,
      "Player": "James Bradberry",
      "Market option": "tackles_plus_assists",
      "Market": "under",
      "Odds": -109,
      "Advantage": 5.3,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 52.1
  },
  {
      "Tweet Date": "10/2/2023",
      "Week": 5,
      "Player": "Jaxon Smith Njibba",
      "Market option": "longest_reception",
      "Market": "under",
      "Odds": -120,
      "Advantage": 5.8,
      "Result": "win",
      "result ($)": "$8.3",
      "implied_probability": 54.5
  },
  {
      "Tweet Date": "10/14/2023",
      "Week": 6,
      "Player": "Jerome Ford",
      "Market option": "rushing_attempts",
      "Market": "under",
      "Odds": 102,
      "Advantage": 5,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 49.5
  },
  {
      "Tweet Date": "10/15/2023",
      "Week": 6,
      "Player": "Joe Mixon",
      "Market option": "rushing_attempts",
      "Market": "over",
      "Odds": -106,
      "Advantage": 6.7,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 51.4
  },
  {
      "Tweet Date": "10/7/2023",
      "Week": 5,
      "Player": "Joshua Dobbs",
      "Market option": "rushing_yards",
      "Market": "over",
      "Odds": -116,
      "Advantage": 5.2,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 53.7
  },
  {
      "Tweet Date": "10/7/2023",
      "Week": 5,
      "Player": "JuJu Smith Schuster",
      "Market option": "receptions",
      "Market": "under",
      "Odds": 120,
      "Advantage": 6.5,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 45.5
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Kyren Williams",
      "Market option": "rushing_yards",
      "Market": "under",
      "Odds": -116,
      "Advantage": 5.4,
      "Result": "win",
      "result ($)": "$8.6",
      "implied_probability": 53.7
  },
  {
      "Tweet Date": "10/15/2023",
      "Week": 6,
      "Player": "Malik Jefferson",
      "Market option": "tackles_plus_assists",
      "Market": "under",
      "Odds": 102,
      "Advantage": 6.5,
      "Result": "win",
      "result ($)": "$10.2",
      "implied_probability": 49.5
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Minkah Fitzpatrick",
      "Market option": "tackles_plus_assists",
      "Market": "over",
      "Odds": -120,
      "Advantage": 6,
      "Result": "win",
      "result ($)": "$8.3",
      "implied_probability": 54.5
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Nate Landman",
      "Market option": "tackles_plus_assists",
      "Market": "over",
      "Odds": -135,
      "Advantage": 6.8,
      "Result": "win",
      "result ($)": "$7.4",
      "implied_probability": 57.4
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Roquan Smith",
      "Market option": "tackles_plus_assists",
      "Market": "over",
      "Odds": -125,
      "Advantage": 5.6,
      "Result": "win",
      "result ($)": "$8.0",
      "implied_probability": 55.6
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Russ Yeast",
      "Market option": "tackles_plus_assists",
      "Market": "over",
      "Odds": 102,
      "Advantage": 6.7,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 49.5
  },
  {
      "Tweet Date": "10/13/2023",
      "Week": 6,
      "Player": "Ryan Neal",
      "Market option": "tackles_plus_assists",
      "Market": "over",
      "Odds": -123,
      "Advantage": 6.1,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 55.2
  },
  {
      "Tweet Date": "10/12/2023",
      "Week": 6,
      "Player": "Skyy Moore",
      "Market option": "receptions",
      "Market": "under",
      "Odds": -111,
      "Advantage": 6.1,
      "Result": "win",
      "result ($)": "$9.0",
      "implied_probability": 52.6
  },
  {
      "Tweet Date": "10/15/2023",
      "Week": 6,
      "Player": "Tee Higgins",
      "Market option": "receptions",
      "Market": "under",
      "Odds": -126,
      "Advantage": 6.7,
      "Result": "win",
      "result ($)": "$7.9",
      "implied_probability": 55.8
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Tony Pollard",
      "Market option": "rushing_yards",
      "Market": "over",
      "Odds": -114,
      "Advantage": 5.2,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 53.2
  },
  {
      "Tweet Date": "10/8/2023",
      "Week": 5,
      "Player": "Wan'Dale Robinson",
      "Market option": "receptions",
      "Market": "over",
      "Odds": 124,
      "Advantage": 6.9,
      "Result": "win",
      "result ($)": "$12.4",
      "implied_probability": 44.4
  },
  {
      "Tweet Date": "10/6/2023",
      "Week": 5,
      "Player": "Wan'Dale Robinson",
      "Market option": "receptions",
      "Market": "over",
      "Odds": -133,
      "Advantage": 6,
      "Result": "win",
      "result ($)": "$7.5",
      "implied_probability": 57.1
  },
  {
      "Tweet Date": "10/7/2023",
      "Week": 5,
      "Player": "Wil Lutz",
      "Market option": "FGM",
      "Market": "over",
      "Odds": -121,
      "Advantage": 6.9,
      "Result": "win",
      "result ($)": "$8.3",
      "implied_probability": 54.7
  },
  {
      "Tweet Date": "10/21/2023",
      "Week": 7,
      "Player": "Lamar Jackson",
      "Market option": "passing_attempts",
      "Market": "over",
      "Odds": -107,
      "Advantage": 6.7,
      "Result": "loss",
      "result ($)": "-$10.0",
      "implied_probability": 51.7
  },
  {
      "Tweet Date": "10/21/2023",
      "Week": 7,
      "Player": "Allen Robinson II",
      "Market option": "receiving_yards",
      "Market": "under",
      "Odds": -119,
      "Advantage": 6.8,
      "Result": "win",
      "result ($)": "$8.4",
      "implied_probability": 54.4
  },
  {
      "Tweet Date": "10/21/2023",
      "Week": 7,
      "Player": "Allen Robinson II",
      "Market option": "longest_reception",
      "Market": "under",
      "Odds": -123,
      "Advantage": 5.9,
      "Result": "win",
      "result ($)": "$8.1",
      "implied_probability": 55.2
  },
  {
      "Tweet Date": "10/22/2023",
      "Week": 7,
      "Player": "Keaontay Ingram",
      "Market option": "longest_rush",
      "Market": "under",
      "Odds": -114,
      "Advantage": 6.4,
      "Result": "win",
      "result ($)": "$8.8",
      "implied_probability": 53.2
  }
]



const VegaLiteChart = () => {
  const chartContainerRef = useRef(null);
  const chartContainerRef2 = useRef(null);

  const vegaLiteSpec = {
      "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
      "description": "A simple bar chart with embedded data.",
      "width": 400,
      "height": 300,
      "data": {
        "values": data,
      },
      "mark": {
        "type": "point",
        "size": 150
      },
      "encoding": {
        "x": {
          "field": "Tweet Date", 
          "type": "temporal",
          "title": "Tweet Date",
          "axis": {
            "titleFontSize": 14,
            "labelFontSize": 12,
          },
          },
        "y": {
          "field": "Advantage", 
          "type": "quantitative",
          "title": "Advantage v. Vegas (%)",
          "scale": {
            "domain": [4.5, 8], // Adjust the starting value here
          },
          "axis": {
            "titleFontSize": 14,
            "labelFontSize": 12,
          },
        },
        "color": {
          "field": "Result",
          "type": "nominal",
          "scale": {
            "range": ["red", "green"]  // Define the colors for "loss" and "win" here
          },
          "title": "Result",
          "legend": {
            "titleFontSize": 16, // Adjust the legend title font size
            "labelFontSize": 14, // Adjust the legend label font size
          },
        },
        "tooltip": [
          {"field": "Player", "title": "Player"},
          {"field": "Market option", "title": "Option"},
          {"field": "Result", "title": "Result"},
          {"field": "Odds", "title": "Odds"},
        ],
      },
      };
    vegaEmbed(chartContainerRef.current, vegaLiteSpec, { actions: false });

    const vegaLiteSpec2 = {
      "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
      "description": "A simple bar chart with embedded data.",
      "width": 400,
      "height": 300,
      "data": {
        "values": data,
      },
      "mark": {
        "type": "point",
        "size": 150
      },
      "encoding": {
        "y": {
          "field": "Advantage", 
          "type": "quantitative",
          "title": "Advantage v. Vegas (%)",
          "scale": {
            "domain": [4.5, 8], // Adjust the starting value here
          },
          "axis": {
            "titleFontSize": 14,
            "labelFontSize": 12,
          },
          },
        "x": {
          "field": "implied_probability", 
          "type": "quantitative",
          "title": "Implied Probability",
          "scale": {
            "domain": [40, 65], // Adjust the starting value here
          },
          "axis": {
            "titleFontSize": 14,
            "labelFontSize": 12,
          },
        },
        "color": {
          "field": "Result",
          "type": "nominal",
          "scale": {
            "range": ["red", "green"]  // Define the colors for "loss" and "win" here
          },
          "title": "Result",
          "legend": {
            "titleFontSize": 16, // Adjust the legend title font size
            "labelFontSize": 14, // Adjust the legend label font size
          },
        },
        "tooltip": [
          {"field": "Player", "title": "Player"},
          {"field": "Market option", "title": "Option"},
          {"field": "Result", "title": "Result"},
          {"field": "Odds", "title": "Odds"},
        ]
      },
      transform: [
        {
          filter: {
            field: 'Result', // Replace with the actual field to filter on
            oneOf: ["win", "loss"],
          },
        },
      ]
      };
    vegaEmbed(chartContainerRef2.current, vegaLiteSpec2, { actions: false });

    return (
      <div>
      <h2>All Tweets</h2>
      <div ref={chartContainerRef}>
        {/* The chart will be rendered in this container */}
      </div>
      <br></br>
      <br></br>

      <h2>Impact of Advantage and Implied Probability</h2>
      <div ref={chartContainerRef2}>
        {/* The chart will be rendered in this container */}
      </div>
      <p>If this was a strike zone hitting chart, I'd throw high and I'd throw outside.</p>
      </div>
    );
  };
  
  export default VegaLiteChart;