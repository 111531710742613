import React, { useEffect, useState, useRef } from 'react';
import vegaEmbed from 'vega-embed';
import { VegaLite } from 'react-vega';

let FUTURES_API_KEY = process.env.REACT_APP_FUTURES_API_KEY;
let FUTURES_API_URL = process.env.REACT_APP_FUTURES_API_URL;

const VegaLiteChart = (props) => {
  const chartContainerRef = useRef(null);
  const [tableData, setTableData] = useState([{id: '2024 Super Bowl Winner_Atlanta Falcons', event: '2024 Super Bowl Winner', option_name: 'Atlanta Falcons', gambet_implied_odds: '1.25', pinnacle_implied_odds: '1.352447930754666'}
  ]);
  const [uniqueEventsData, setEventsData] = useState([]);

  const fetchData = async () => {
    const apiKey = FUTURES_API_KEY;
    const url = FUTURES_API_URL;
    try {
      const response = await fetch(url, {
        headers: {
          'x-api-key': apiKey,
          'sub-path': props.subPath,
        },
      });
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log(tableData);
  useEffect(() => {
  // Filter and update unique combinations only after tableData is updated.
    const uniqueEvents = new Set();
    tableData.forEach((item) => {
      const futureEvent = item.event;
      uniqueEvents.add(futureEvent);
    });
    setEventsData(Array.from(uniqueEvents));
    const vegaLiteSpec = {
      "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
      "data": {
        "values": tableData,
      },
      "mark": "point",
      "encoding": {
        "x": {
          "field": "gambet_implied_odds", 
          "type": "quantitative",
          "title": "Gambet Implied Odds"
          },
        "y": {
          "field": "pinnacle_implied_odds", 
          "type": "quantitative",
          "title": "Vegas Implied Odds"
        },
        "tooltip": [
          {"field": "event", "title": "Event"},
          {"field": "option_name", "title": "Option"},
        ],
      },
      };
    vegaEmbed(chartContainerRef.current, vegaLiteSpec, { actions: false });
    }, [tableData, props.week]);

    return (
      <div ref={chartContainerRef}>
        {/* The chart will be rendered in this container */}
      </div>
    );
  };
  
  export default VegaLiteChart;