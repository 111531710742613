import { useState, useEffect } from 'react';
import WeekliesTable from '../components/WeekliesTable'


// create a content component that will be returned with other components in it
const Content = () => {
	  return (
	<div className="content">
	<h1>NFL Week 8 Markets</h1>
	<br></br>
	<p>GambetDC vs Vegas. Lower implied odds mean you get paid more for the exact same bet. Tables sorted by lowest implied odds compared to vegas in each matchup.</p>
	<br></br>

	<h2>WARN: VEGAS UPDATES ARE DELAYED DURING / AFTER GAMES ATM. DATA WILL BE INACCURATE</h2>

	<br></br>

	<WeekliesTable subPath='NFL/weeklies/state' week='8'/>
	<br></br>
	</div>
  );
}
// return the content component when the page is loaded
export default Content;