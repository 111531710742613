import { useState, useEffect } from 'react';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import DynamicTable from '../components/DynamicTable'
import DynamicTableSum from '../components/DynamicTableSum'

let future_bets = [
    {
    'market': '2023 NFC East Winner',
    'option': 'Philadelphia Eagles',   
    'date': '2023-05-12',
    'odds': '+110',
    'implied_prob': '47.6',
    'units': '2',
    'outcome': 'pending',
	'unit_diff': 0
    }
]
let week_1 = [
    {
		'market': 'LAR @ SEA',
		'option': 'LAR +7.5',   
		'date': '2023-07-31',
		'odds': '-135',
		'implied_prob': '57.45',
		'units': '2',
		'outcome': 'Win',
		'unit_diff': 1.48
	},
    {
		'market': 'JAX @ IND',
		'option': 'JAX -2.5',   
		'date': '2023-09-08',
		'odds': '-159',
		'implied_prob': '57.45',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.63
	}
	,
    {
		'market': 'CAR @ ATL',
		'option': 'Over 37.5',   
		'date': '2023-09-08',
		'odds': '-135',
		'implied_prob': '57.45',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1
	},
    {
		'market': 'GB @ CHI',
		'option': 'GB +3.5',   
		'date': '2023-09-08',
		'odds': '-159',
		'implied_prob': '57.45',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.63
	}
]

let week_2 = [
    {
		'market': 'SF @ LAR',
		'option': 'SF -6.5',   
		'date': '2023-09-11',
		'odds': '-135',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.74
	}
	,
    {
		'market': 'NO @ CAR',
		'option': 'Under 38.5',   
		'date': '2023-09-11',
		'odds': '+135',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 1.35
	}
]

let week_4 = [
	{
		'market': 'Rondale Moore - Longest Reception',
		'option': 'Under 14.5',   
		'date': '2023-09-29',
		'odds': '-128',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.78		
	},
	{
		'market': 'Anders Carlson - Field Goal Made',
		'option': 'Over 1.5',   
		'date': '2023-09-28',
		'odds': '+120',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 1.2		
	},
	{
		'market': 'Kaden Ellis - Tackles + Assists',
		'option': 'Over 6.5',   
		'date': '2023-09-27',
		'odds': '-139',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1		
	},
	{
		'market': 'Romeo Doubs - Receptions',
		'option': 'Over 3.5',   
		'date': '2023-09-27',
		'odds': '+126',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 1.26
	},
	{
		'market': 'Dontayvion Wicks - Receiving Yards',
		'option': 'Under 18.5',   
		'date': '2023-09-27',
		'odds': '-119',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.84
	},
	{
		'market': 'Dontayvion Wicks - Receptions',
		'option': 'Under 1.5',   
		'date': '2023-09-27',
		'odds': '-120',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.83
	},
	{
		'market': 'Kyle Pitts - Receptions',
		'option': 'Under 3.5',   
		'date': '2023-09-27',
		'odds': '-141',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.71
	},
	{
		'market': 'Vikings -2.5',
		'option': 'Vikings',   
		'date': '2023-09-27',
		'odds': '-169',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.63
	},
	{
		'market': 'Tyler Higbee - Receptions',
		'option': 'Under 4.5',   
		'date': '2023-09-27',
		'odds': '-133',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1
	},
	{
		'market': 'Patriots +7.5',
		'option': 'Patriots',   
		'date': '2023-09-27',
		'odds': '-125',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1
	},
	{
		'market': 'Seahawks +3.5',
		'option': 'Seahawks',   
		'date': '2023-09-27',
		'odds': '-167',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.6
	},	{
		'market': 'DK Metcalf - Receiving Yards',
		'option': 'Over 60.5',   
		'date': '2023-10-01',
		'odds': '-119',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1
	},
	{
		'market': 'DK Metcalf - Receptions',
		'option': 'Over 4.5',   
		'date': '2023-10-01',
		'odds': '-130',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1
	},
	{
		'market': 'Jaxon Smith Njigba - Longest Reception',
		'option': 'Under 15.5',   
		'date': '2023-10-01',
		'odds': '-118',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.85
	},
]

let week_5 = [
	{
		'market': 'SF @ DAL',
		'option': 'SF -2.5',   
		'date': '2023-10-08',
		'odds': '-139',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.72
	},
	{
		'market': 'Wil Lutz Over 1.5 Made FG',
		'option': 'Over 1.5',   
		'date': '2023-10-08',
		'odds': '-122',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.82
	},
	{
		'market': 'Joshua Dobbs Rush Yds',
		'option': 'Over 21.5',   
		'date': '2023-10-08',
		'odds': '-116',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1
	},
	{
		'market': 'Dallas Goedert Receptions',
		'option': 'Over 3.5',   
		'date': '2023-10-01',
		'odds': '-110',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.91
	},
	{
		'market': `Wan'Dale Robinson Receptions`,
		'option': 'Over 3.5',   
		'date': '2023-10-08',
		'odds': '-125',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.8
	},
	{
		'market': `JuJu Smith-Schuster Receptions`,
		'option': 'Under 2.5',   
		'date': '2023-10-08',
		'odds': '-108',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1
	},
	{
		'market': `Allen Robinson II Receiving Yds`,
		'option': 'Under 28.5',   
		'date': '2023-10-08',
		'odds': '-137',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1
	},
	{
		'market': `Azeez Al-Shaair Tackles + Assists`,
		'option': 'Over 7.5',   
		'date': '2023-10-08',
		'odds': '-127',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.89
	},
	{
		'market': `DeAndre Hopkins Receptions`,
		'option': 'Over 4.5',   
		'date': '2023-10-08',
		'odds': '-101',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.99
	},
	{
		'market': `Chris Moore Receptions`,
		'option': 'Under 2.5',   
		'date': '2023-10-08',
		'odds': '-111',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.9
	},
	{
		'market': `DJ Moore Reception Yards`,
		'option': 'Over 52.5',   
		'date': '2023-10-05',
		'odds': '-118',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.85
	},
	{
		'market': `Sam Howell Rushing Yards`,
		'option': 'Over 12.5',   
		'date': '2023-10-05',
		'odds': '-122',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Win',
		'unit_diff': 0.82
	},
	{
		'market': `Brian Robinson Rushing Attempts`,
		'option': 'Over 15.5',   
		'date': '2023-10-05',
		'odds': '-102',
		'implied_prob': '',
		'units': '1',
		'outcome': 'Loss',
		'unit_diff': -1
	},
]

let week_6 = []

let week_7 = []

let intro_markdown = `
## [BetsFriend](/betsfriend) Bets
Tracking is becoming a huge PITA as player props blow up our volume - looking into third party services that I might use to display this stuff. 

I need to add my bets from week 6 and 7; but you should checkout the [blog post on tweet results](blog/12) until then.

`


// create a content component that will be returned with other components in it
const Content = () => {
	  return (
	<div className="content">
		<div className="intro">
			<Markdown children={intro_markdown} remarkPlugins={[remarkGfm]}/>
		</div>
		<br></br>
		<h2>2023 Week 5 NFL Bets</h2>
	<table>
		  <thead>
			<tr>
			  <th>Date</th>
			  <th>Market</th>
			  <th>Option</th>
			  <th>Odds</th>
			  <th>Units</th>
			  <th>Outcome</th>
			  <th>Units Won/Lost</th>
			</tr>
		  </thead>
		  <tbody>
			{week_5.map((row, index) => (
			  <tr key={index}>
				<td>{row.date}</td>
				<td>{row.market}</td>
                <td>{row.option}</td>
				<td>{row.odds}</td>
				<td>{row.units}</td>
                <td>{row.outcome}</td>
                <td>{row.unit_diff}</td>
			  </tr>
			))}
			<tr>
				<td><b>Total Results</b></td>
				<td></td>
				<td></td>
				<td></td>
				<td><b>{parseFloat(week_5.reduce((acc, row) => acc + parseFloat(row.units), 0)).toFixed(0)}</b></td>
				<td></td>
				<td><b>{parseFloat(week_5.reduce((acc, row) => acc + parseFloat(row.unit_diff), 0)).toFixed(2)}</b></td>
			</tr>
		  </tbody>
		</table>
		<br></br>
		<br></br>
		<h2>2023 Week 4 NFL Bets</h2>
	<table>
		  <thead>
			<tr>
			  <th>Date</th>
			  <th>Market</th>
			  <th>Option</th>
			  <th>Odds</th>
			  <th>Units</th>
			  <th>Outcome</th>
			  <th>Units Won/Lost</th>
			</tr>
		  </thead>
		  <tbody>
			{week_4.map((row, index) => (
			  <tr key={index}>
				<td>{row.date}</td>
				<td>{row.market}</td>
                <td>{row.option}</td>
				<td>{row.odds}</td>
				<td>{row.units}</td>
                <td>{row.outcome}</td>
                <td>{row.unit_diff}</td>
			  </tr>
			))}
			<tr>
				<td><b>Total Results</b></td>
				<td></td>
				<td></td>
				<td></td>
				<td><b>{parseFloat(week_4.reduce((acc, row) => acc + parseFloat(row.units), 0)).toFixed(0)}</b></td>
				<td></td>
				<td><b>{parseFloat(week_4.reduce((acc, row) => acc + parseFloat(row.unit_diff), 0)).toFixed(2)}</b></td>
			</tr>
		  </tbody>
		</table>
		<br></br>

			<h2>2023 Week 2 NFL Bets</h2>
	<table>
		  <thead>
			<tr>
			  <th>Date</th>
			  <th>Market</th>
			  <th>Option</th>
			  <th>Odds</th>
			  <th>Units</th>
			  <th>Outcome</th>
			  <th>Units Won/Lost</th>
			</tr>
		  </thead>
		  <tbody>
			{week_2.map((row, index) => (
			  <tr key={index}>
				<td>{row.date}</td>
				<td>{row.market}</td>
                <td>{row.option}</td>
				<td>{row.odds}</td>
				<td>{row.units}</td>
                <td>{row.outcome}</td>
                <td>{row.unit_diff}</td>
			  </tr>
			))}
			<tr>
				<td><b>Total Results</b></td>
				<td></td>
				<td></td>
				<td></td>
				<td><b>{parseFloat(week_2.reduce((acc, row) => acc + parseFloat(row.units), 0)).toFixed(0)}</b></td>
				<td></td>
				<td><b>{parseFloat(week_2.reduce((acc, row) => acc + parseFloat(row.unit_diff), 0)).toFixed(2)}</b></td>
			</tr>
		  </tbody>
		</table>
		<br></br>
	<h2>2023 Week 1 NFL Bets</h2>
	<table>
		  <thead>
			<tr>
			  <th>Date</th>
			  <th>Market</th>
			  <th>Option</th>
			  <th>Odds</th>
			  <th>Units</th>
			  <th>Outcome</th>
			  <th>Units Won/Lost</th>
			</tr>
		  </thead>
		  <tbody>
			{week_1.map((row, index) => (
			  <tr key={index}>
				<td>{row.date}</td>
				<td>{row.market}</td>
                <td>{row.option}</td>
				<td>{row.odds}</td>
				<td>{row.units}</td>
                <td>{row.outcome}</td>
                <td>{row.unit_diff}</td>
			  </tr>
			))}
			<tr>
				<td><b>Total Results</b></td>
				<td></td>
				<td></td>
				<td></td>
				<td><b>{parseFloat(week_1.reduce((acc, row) => acc + parseFloat(row.units), 0)).toFixed(0)}</b></td>
				<td></td>
				<td><b>{parseFloat(week_1.reduce((acc, row) => acc + parseFloat(row.unit_diff), 0)).toFixed(2)}</b></td>
			</tr>
		  </tbody>
		</table>
<br></br>

		<br></br>
	<h2>2023 NFL Future Bets</h2>
	<br></br>
    <table>
		  <thead>
			<tr>
			  <th>Date</th>
			  <th>Market</th>
			  <th>Option</th>
			  <th>Odds</th>
			  <th>Units</th>
			  <th>Outcome</th>
			  <th>Units Won/Lost</th>
			</tr>
		  </thead>
		  <tbody>
			{future_bets.map((row, index) => (
			  <tr key={index}>
				<td>{row.date}</td>
				<td>{row.market}</td>
                <td>{row.option}</td>
				<td>{row.odds}</td>
				<td>{row.units}</td>
                <td>{row.outcome}</td>
                <td>{row.unit_diff}</td>
			  </tr>
			))}
			<tr>
				<td><b>Total Results</b></td>
				<td></td>
				<td></td>
				<td></td>
				<td><b>{parseFloat(future_bets.reduce((acc, row) => acc + parseFloat(row.units), 0)).toFixed(0)}</b></td>
				<td></td>
				<td><b>{parseFloat(future_bets.reduce((acc, row) => acc + parseFloat(row.unit_diff), 0)).toFixed(2)}</b></td>
			</tr>
		  </tbody>
		</table>
	</div>
  );
}
// return the content component when the page is loaded
export default Content;