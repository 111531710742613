import { useState, useEffect } from 'react';
import DynamicTableSum from '../components/DynamicTableSum'
import FuturesTable from '../components/FuturesTable'
import VegaLiteChart from '../components/VegaLiteChart'


// create a content component that will be returned with other components in it
const Content = () => {
	  return (
	<div className="content">
	<h1>NFL Futures Markets</h1>
	<br></br>
	<h2>WARN: VEGAS UPDATES ARE DELAYED DURING / AFTER GAMES ATM. DATA WILL BE INACCURATE</h2>
	<br></br>
	<FuturesTable subPath='/NFL/futures/state'/>
	</div>
  );
}
// return the content component when the page is loaded
export default Content;