import { useState, useEffect } from 'react';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import DynamicTable from '../components/DynamicTable'
import DynamicTableSum from '../components/DynamicTableSum'
import WeekliesTable from '../components/WeekliesTable'
import { Tweet } from 'react-tweet'


let FUTURES_API_KEY = process.env.REACT_APP_FUTURES_API_KEY
let FUTURES_API_URL = process.env.REACT_APP_FUTURES_API_URL

const TwitterEmbed = () => {
	// Replace this with the actual Twitter embed code you obtained from Twitter.
	const tweetEmbedCode = '<!-- Twitter embed code goes here -->';
}

let intro_markdown = `
## Welcome to BetsFriend!
NFL betting insights for DC residents. You can still see market summaries on this page; but I've begun moving notifications to [@fancyworms on twitter](https://twitter.com/fancyworms).

## Notifications
I've begun moving notifications to [@fancyworms on twitter](https://twitter.com/fancyworms)
`

let subpage_markdown = `

## Markets

### [NFL Week 8](bf/NFL/weeklies):
Each NFL matchup's moneyline and spread compared to Vegas. Week to week markets tend to have way more opportunities than futures.

### [NFL Futures](bf/NFL/futures)
The Futures markets for the super bowl, conference, and division winners. I don't expect to find any easy money here; but hey it's fun to look at. May add more NFL futures markets... in the future.

## Background

### [BetsFriend Theory](blog/2):
A nice long post on the idea and execution of BetsFriend.

### [BetsFriend Bets - NFL 2023](bf/betsfriendbets):
Putting our money where our mouth is. Track the bets I make based on BetsFriend.
`

// create a content component that will be returned with other components in it
const Content = () => {
	  return (
	<div className="content">
		<div className="intro">
			<Markdown children={intro_markdown} remarkPlugins={[remarkGfm]}/>
		</div>
		<div data-theme="light" ><Tweet id="1708996758221517003" /></div>
	<br></br>
	<br></br>
	<div className="subpages">
			<Markdown children={subpage_markdown} remarkPlugins={[remarkGfm]}/>
		</div>
	</div>
  );
}
// return the content component when the page is loaded
	       //<<WeekliesTable subPath='NFL/weeklies/1/notifications' week='1'/>>
		   //<DynamicTable subPath='/NFL/futures/notifications' futureMarket='no'/>

export default Content;