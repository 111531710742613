import { useState, useEffect } from 'react';

let FUTURES_API_KEY = process.env.REACT_APP_FUTURES_API_KEY;
let FUTURES_API_URL = process.env.REACT_APP_FUTURES_API_URL;

const DynamicTable = (props) => {
  const [tableData, setTableData] = useState([]);
  const [uniqueCombinationsData, setCombinationsData] = useState([]);

  const fetchData = async () => {
    const apiKey = FUTURES_API_KEY;
    const url = FUTURES_API_URL;
    try {
      const response = await fetch(url, {
        headers: {
          'x-api-key': apiKey,
          'sub-path': props.subPath,
        },
      });
      const data = await response.json();
      // Sort the data by lowest odds_diff descending
      const sortedData = data.sort((a, b) => parseFloat(a.odds_diff) - parseFloat(b.odds_diff));
      setTableData(sortedData);      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Filter and update unique combinations only after tableData is updated.
    const filteredArray = tableData.filter((obj) => obj.week === props.week);
    const uniqueCombinations = new Set();
    filteredArray.forEach((item) => {
      const combination = `${item.event_date}_${item.away}_${item.home}`;
      uniqueCombinations.add(combination);
    });
    setCombinationsData(Array.from(uniqueCombinations));
  }, [tableData, props.week]);

  return (
    <div>
      {uniqueCombinationsData.map((combination) => (
        <div key={combination}>
          <h2>
            {combination.split('_')[1]} @ {combination.split('_')[2]}
          </h2>
          <table>
            <thead>
              <tr>
                <th>Updated</th>
                <th>Market</th>
                <th>Option</th>
                <th>Vegas</th>
                <th>GambetDC</th>
                <th>Diff</th>
              </tr>
            </thead>
            <tbody>
              {tableData
                .filter((row) => `${row.event_date}_${row.away}_${row.home}` === combination)
                .map((row, index) => (
                  <tr key={index}>
                    <td>{new Date(row.identified_at).toLocaleString()}</td>
                    <td>{row.market_type}</td>
                    <td>{row.option_name}</td>
                    <td>{parseFloat(row.pinnacle_implied_odds).toFixed(2) + '%'}</td>
                    <td>{parseFloat(row.gambet_implied_odds).toFixed(2) + '%'}</td>
                    <td>{parseFloat(row.odds_diff).toFixed(2) + '%'}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default DynamicTable;
