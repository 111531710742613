import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const markdown = `
fancyworms is a small site about small things. 
`
const blogs = [
	{
		title: 'player prop receipts',
		date: '2023-10-24',
		description: 'ugly graph happy money',
		visible: true,
		id:12,
		url: '/blog/12'
	},
	{
		title: 'prop train keeps on rolling',
		date: '2023-10-10',
		description: 'so much text',
		visible: true,
		id:11,
		url: '/blog/11'
	},
	{
		title: 'week 5, some player props are live',
		date: '2023-10-03',
		description: 'welcome to the poke shop',
		visible: true,
		id:10,
		url: '/blog/10'
	},
	{
		title: 'a poke shop, bad lines, player props, twilio regulations, and tweeting (x-ing?)',
		date: '2023-09-28',
		description: 'i am not poke shop man',
		visible: true,
		id:9,
		url: '/blog/9'
	},
	{
		title: 'its week 3, gambet odds have soured',
		date: '2023-09-19',
		description: 'i am still very busy :(',
		visible: true,
		id:8,
		url: '/blog/8'
	},
	{
		title: 'its week 2, things are a bit broken',
		date: '2023-09-11',
		description: 'i am very busy :(',
		visible: true,
		id:7,
		url: '/blog/7'
	},
	{
		title: 'Week 1 is live',
		date: '2023-07-31',
		description: 'ramming this in before the end of july',
		visible: true,
		id:6,
		url: '/blog/6'
	},
	{
		title: 'A league of their own',
		date: '2023-06-29',
		description: 'danny out',
		visible: true,
		id:5,
		url: '/blog/5'
	},
	{
		title: 'BetsFriend is betting',
		date: '2023-06-21',
		description: '',
		visible: true,
		id:4,
		url: '/blog/4'
	},
	{
		title: 'NFL Futures are Live',
		date: '2023-05-13',
		description: '',
		visible: true,
		id:3,
		url: '/blog/3'
	},
	{
		title: 'Breaking Gambet | BetsFriend Theory',
		date: '2023-05-13',
		description: '',
		visible: true,
		id:2,
		url: '/blog/2'

	},
	{
		title: 'Welcome to Fancyworms',
		date: '2023-05-01',
		description: 'A brief introduction to the site',
		visible: false,
		id:1,
		url: '/about'
	},
]

const BlogList = (props) => {
	const navigate = useNavigate();
	const [selectedBlog, setSelectedBlog] = useState(null);

	const handleBlogClick = (blog) => {
		setSelectedBlog(blog);
		navigate(blog.url);
	};
	return (
		<div className="blog-list">
			{blogs.map((blog) => (
				<div className="blog-preview" key={blog.id} onClick={() => handleBlogClick(blog)}>
					<h2>{blog.title}</h2>
					<p><i>{blog.date}</i></p>
				</div>
			))}
		</div>
	);
};


const Home = () => {
	return (
		<div className="content">
		<BlogList />
		</div>
	);
}



export default Home;
