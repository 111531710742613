import { useState, useEffect } from 'react';

let FUTURES_API_KEY = process.env.REACT_APP_FUTURES_API_KEY
let FUTURES_API_URL = process.env.REACT_APP_FUTURES_API_URL

const DynamicTable = (props) => {
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const apiKey = FUTURES_API_KEY;
			const url = FUTURES_API_URL;
		console.log(props)
		try {
			const response = await fetch(url, {
				headers: {
					'x-api-key': apiKey,
					'sub-path': props.subPath
				}
			});
			const data = await response.json();
			setTableData(data);
		} catch (error) {
			console.error(error);
		}
	};
	fetchData();
	}, []);
	return (
		<table>
		  <thead>
			<tr>
			  <th>Time</th>
			  <th>Future Market</th>
			  <th>Side to Bet</th>
			  <th>Vegas Implied Odds</th>
			  <th>GambetDC Implied Odds</th>
			  <th>Difference in Implied Odds</th>
			</tr>
		  </thead>
		  <tbody>
			{tableData.map((row, index) => (
			  <tr key={index}>
				<td>{new Date(row.identified_at).toLocaleString()}</td>
				<td>{row.event}</td>
				<td>{row.option_name}</td>
				<td>{parseFloat(row.pinnacle_implied_odds).toFixed(2)+'%'}</td>
				<td>{parseFloat(row.gambet_implied_odds).toFixed(2)+'%'}</td>
				<td>{parseFloat(row.odds_diff).toFixed(2)+'%'}</td>
			  </tr>
			))}
		  </tbody>
		</table>
	);
};

// create a content component that will be returned with other components in it
const Content = () => {
	  return (
	<div className="content">
	<h1>Current Notifications</h1>
	<br></br>
	<DynamicTable subPath={'/NFL/futures/notifications'}/>
	</div>
  );
}
// return the content component when the page is loaded
export default Content;