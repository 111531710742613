import { useState, useEffect } from 'react';
import Markdown from 'react-markdown'

const markdown = `
## Welcome

Fancyworms is a small site with deep projects. 

I learn by doing. The goal with fancyworms projects is to get some skin in the game and learn something interesting along the way.

The main project at the moment is BetsFriend, so go take a [look](/betsfriend).

### contact us: 
Questions about projects, interested in collaborating, have a project in mind, or just want to chat? 

Send an email: fancywormshq@gmail.com
`

const content = () => {
	return (
		<div className="content">
		<Markdown children={markdown} />
		</div>
	);
}

export default content;